import type { FC } from "react";
import "./main.css";

export const Main: FC = () => {
  const recipient = "example@gmail.com";
  const ccRecipients = ["cc1@example.com", "cc2@example.com"];
  const subject = "件名です";
  const body = `\n\n荷物が届いています\n取りに来てください\n\nよろしく`;

  // CC宛先をカンマで区切って文字列に変換
  const cc = ccRecipients.join(",");

  const mailtoLink = `mailto:${recipient}?cc=${encodeURIComponent(
    cc
  )}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  console.log("mailtoLink:", mailtoLink);

  return <a href={mailtoLink}>to Mail</a>;
};
